@font-face {
  font-family: 'geomanistregular';
  src: url('./Assets/geomanist-regular-webfont.eot');
  src: url('./Assets/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Assets/geomanist-regular-webfont.woff2') format('woff2'),
       url('./Assets/geomanist-regular-webfont.woff') format('woff'),
       url('./Assets/geomanist-regular-webfont.ttf') format('truetype'),
       url('./Assets/geomanist-regular-webfont.svg#geomanistregular') format('svg');
  font-weight: normal;
  font-style: normal;

}


/* reset for all browsers */
* {margin: 0; padding: 0;}

#body {
  margin: 0;
  font-family: "geomanistregular";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: 3.375rem; */
  /* color: #6c757d; */
  color: #2a333b;;
}

* {font-family: "geomanistregular";}







code {
  font-family: "geomanistregular";
}

@media (min-width: 992px) {
  #body {
    padding-top: 0;
    padding-left: 17rem;
  }
  }

  :root {
    /* --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #bd5d38;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #bd5d38;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #35dcaa;
    --light: #f8f9fa;
    --dark: #343a40; */
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;

    /* --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

/* ABOUT-------------------------------------------------------------   */

.subheading {
  font-family: "geomanistregular";
  font-size: 1.5rem;
}

.mb-5 {
margin-bottom: 3rem !important;
}

a {
color: #343a40;
text-decoration: none;
background-color: transparent;
}
a:hover {
color:  rgba(255, 255, 255, 0.75);;
text-decoration: underline;
}

a:not([href]) {
color: inherit;
text-decoration: none;
}
a:not([href]):hover {
color: inherit;
text-decoration: none;
}

p {
margin-top: 0;
margin-bottom: 1rem;
font-family: "geomanistregular";
}

img {
vertical-align: middle;
border-style: none;
}

.float-left {
float: left !important;
}

.social-icons .social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background-color: #495057;
  color: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  font-size: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
}
.social-icons .social-icon:last-child {
  margin-right: 0;
}
.social-icons .social-icon:hover {
  background-color: #343a40;
}

/* // Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 767px) { 
.img-fluid{
    width:80%;}
}
/* // Medium devices (tablets, 768px and up) */
@media screen and (max-width: 979px) {
.img-fluid{
    width: 55%;}
}

/* // Large devices (desktops, 992px and up) */
@media screen and (min-width: 980px) {
.img-fluid{
    width: 35%;}
}

.mr-4 {
  margin-right: 1.5rem !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  p {
    orphans: 3;
    widows: 3;
  }
  
  @page {
    size: a3;
  }
  #body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }
}


/* PORTFOLIO-------------------------------------------------------------   */

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: auto;
  margin-left: auto;
  /* margin: 0 auto; */
  text-align: center;
}
/* .no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
} */

/* PROJECT-------------------------------------------------------------   */

/* .blockContent{
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  width: 90%;
} */

.mb-0 {
  margin-bottom: 0 !important;
}

.projectImg {
  vertical-align: middle;
  border-style: solid;
  border-color: #343a40; 
  width: 100%;
}

.portfolio-item {
  display: block;
  position: relative;
  /* overflow: hidden; */
  max-width: 90%;
  /* margin-left: auto;
  margin-right: auto; */
  margin-top: 2rem;
  margin-bottom: 5px;
  width: auto;  
  height: auto;
  margin: 0 auto;
}

.img-thumbnail {
  /* padding: 0.25rem; */
  background-color:  rgba(255, 255, 255, 0.75);
  border: 1px solid #343a40;
  /* border-radius: 0.25rem; */
  max-width: 100%;
  height: auto;
  margin-bottom: 20%;
  padding-bottom: 30%;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #343a40;
  text-align: center;
  caption-side: bottom;
}

.portfolio-item .caption {
  display: flex;
  height: auto;
  width: 100%;
  background-color: #343a40;
  position: absolute;
  bottom: 0;
  /* z-index: 2; */
  margin-left: auto;
  margin-right: auto;
  align-self: flex-end;
  padding: 0.75rem;



}

.portfolio-item .caption .caption-content {
  /* margin: auto 2rem 2rem; */
  margin: 1px;
  color: rgba(255, 255, 255, 0.75);
  /* background:#c2af1d;  */
  align-self: flex-end;
  margin-bottom: 2px;
  height: auto;
  width: auto;
}

.portfolio-item .caption .caption-content .protitle h2{
  font-size: 2rem;
  text-transform: uppercase;
  color:#dff1f7;
}



.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .img-thumbnail {
    /* padding: 0.25rem; */
    background-color:  rgba(255, 255, 255, 0.75);
    border: 1px solid #343a40;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    margin-bottom: auto;
    padding: 1rem;
  }
  }



@media (min-width: 992px) {
  .portfolio-item {
    /* max-width: none; */
    margin: 0.79rem;
    min-width:100%;
    width: auto;
  }
  .portfolio-item .caption {
    transition: background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, background-color 0.7s;
    transition: clip-path 0.25s ease-out, background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px);
    height:37%;
    padding: 1rem;
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
    margin: auto;
    width: 100%
    }
  .portfolio-item img {
    transition: -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(1rem);
    clip-path: inset(1rem);
  }
  .portfolio-item:hover .caption {
    background-color:#343a40;
    -webkit-clip-path: inset(1rem);
    clip-path: inset(1rem);
  }

  

}

h1, h3, h4, h5, h6,
.h1, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "geomanistregular";
  font-weight: 700;
  line-height: 1.2;
  color: #343a40;
}

.text-center {
  text-align: center !important;
}

/* CONTAINERFLUID-------------------------------------------------------------   */

.container, .container-fluid, .container-lg {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.p-0 {
  padding: 0 !important;
}

main.resume-section {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  max-width: 75rem;
}
main.resume-section .resume-section-content {
  width: 100%;
  
}

@media (min-width: 768px) {
  .resume-section {
    min-height: 100vh;
    display: flex;
  }
}
@media (min-width: 992px) {
  .resume-section {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
  }
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, .h1 {
  font-size: 4.5rem;
}

h1 {
  line-height: 1;
}

/* FOOTER-------------------------------------------------------------   */

footer{

position: fixed;
left: 0;
bottom: 0;
width: 100%;
background-color: #343a40
;
color: #dff1f7;
text-align: center;
margin: 0 auto;
padding: 7px;
height: auto;
}

/* NAV-------------------------------------------------------------   */

@media (min-width: 576px) {
  .container {
  max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
  max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
  max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
  max-width: 1140px;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
/* ---------------------------------------------------------- */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
} 

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
      }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
      flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
      display: none;
  }
}

/* --------------------------------------------------- */


.navbar-dark .navbar-brand {
  color:#dff1f7;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link {
  /* color: #c2af1d; */
  color: 
  /* #d4c792; */
  /* #d4c58a; */
  #dff1f7;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text .a {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-text .a:hover, .navbar-dark .navbar-text .a:focus {
  color: rgba(255, 255, 255, 0.75);
}

.bg-primary {
  background-color: #343a40 !important;
}

.a.bg-primary:hover, .a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #343a40 !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

#sideNav .navbar-nav .nav-item .nav-link {
  letter-spacing: 0.05rem;
  font-family: "geomanistregular";
}
#sideNav .navbar-toggler:focus {
  outline-color: #dff1f7;
}

@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh;
  }
  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }
  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }
  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }
  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.d-block {
  display: block !important;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}

.a {
  color: #343a40;
  text-decoration: none;
  background-color: transparent;
}
.a:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}

.a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: "geomanistregular";
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}


.collapse:not(.show) {
  display: none;
}


.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

/* UL */
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}