.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} 


/* CLASS CONTAINER */
    /* .container {
        min-width: 992px !important;
    } */
/* 
    .container {
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
    } */
    /* @media (min-width: 576px) {
        .container {
        max-width: 540px;
        }
    }
    @media (min-width: 768px) {
        .container {
        max-width: 720px;
        }
    }
    @media (min-width: 992px) {
        .container {
        max-width: 960px;
        }
    }
    @media (min-width: 1200px) {
        .container {
        max-width: 1140px;
        }
    } */
/* CLASS CONTAINER-FLUID, CONTAINER-(SIZES)   */
    /* .container-fluid, .container-lg {
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
    } */
/*     
    @media (min-width: 576px) {
        .container {
        max-width: 540px;
        } */
    /* }
    @media (min-width: 768px) {
        .container {
        max-width: 720px;
        }
    }
    @media (min-width: 992px) {
        .container-lg, .container {
        max-width: 960px;
        }
    }
    @media (min-width: 1200px) {
        .container-lg, .container {
        max-width: 1140px;
        }
    } */

/* CLASS NAV */
    /* .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    } */


/* CLASS NAVBAR  ------------------------------------GO BACK TO THIS */

/* ---------------------------------------------------------------------------------- */
    /* .navbar {
        display: none;
      } */

/* CLASS NAVBAR-EXPAND-LG*/
    /* @media (max-width: 991.98px) {
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid,
        .navbar-expand-lg > .container-sm,
        .navbar-expand-lg > .container-md,
        .navbar-expand-lg > .container-lg,
        .navbar-expand-lg > .container-xl {
            padding-right: 0;
            padding-left: 0;
            }
    }

    @media (min-width: 992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-lg .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid,
        .navbar-expand-lg > .container-sm,
        .navbar-expand-lg > .container-md,
        .navbar-expand-lg > .container-lg,
        .navbar-expand-lg > .container-xl {
            flex-wrap: nowrap;
        }
        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-lg .navbar-toggler {
            display: none;
        }
    } */

/* CLASS NAVBAR-DARK */
    /* .navbar-dark .navbar-brand {
        color:#c2af1d;
    }
    .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
        color: #fff;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #c2af1d;
    }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: rgba(255, 255, 255, 0.75);
    }
    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
        color: #fff;
    }
    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.1);
    }
    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, 0.5);
    }
    .navbar-dark .navbar-text .a {
        color: #fff;
    }
    .navbar-dark .navbar-text .a:hover, .navbar-dark .navbar-text .a:focus {
        color: #fff;
    } */


/* CLASS BG-PRIMARY */
    /* .bg-primary {
        background-color: #343a40 !important;
      }
      
      .a.bg-primary:hover, .a.bg-primary:focus,
      button.bg-primary:hover,
      button.bg-primary:focus {
        background-color: #343a40 !important;
      } */

/* CLASS FIXED-TOP       */
      /* .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
      } */

/* ID SIDENAV */
      /* #sideNav .navbar-nav .nav-item .nav-link {
        letter-spacing: 0.05rem; */
        /* font-family: 'Alata', sans-serif; */
      /* }
      #sideNav .navbar-toggler:focus {
        outline-color: #c2af1d;
      }
      
      @media (min-width: 992px) {
        #sideNav {
          text-align: center;
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          width: 17rem;
          height: 100vh;
        }
        #sideNav .navbar-brand {
          display: flex;
          margin: auto auto 0;
          padding: 0.5rem;
        }
        #sideNav .navbar-brand .img-profile {
          max-width: 10rem;
          max-height: 10rem;
          border: 0.5rem solid rgba(255, 255, 255, 0.2);
        }
        #sideNav .navbar-collapse {
          display: flex;
          align-items: flex-start;
          flex-grow: 0;
          width: 100%;
          margin-bottom: auto;
        }
        #sideNav .navbar-collapse .navbar-nav {
          flex-direction: column;
          width: 100%;
        }
        #sideNav .navbar-collapse .navbar-nav .nav-item {
          display: block;
        }
        #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
          display: block;
        }
      } */

/* CLASS NAVBAR BRAND */
    /* .navbar-brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
    }
    .navbar-brand:hover, .navbar-brand:focus {
        text-decoration: none;
    } */

/* CLASS D-BLOCK */
    /* .d-block {
        display: block !important;
    }

    @media (min-width: 992px) {
        .d-lg-none {
          display: none !important;
        }
    } */
    
/* CLASS A (USE INPLACE OF <A> ELEMENT) */
    /* .a {
        color: #343a40;
        text-decoration: none;
        background-color: transparent;
    }
    .a:hover {
        color: #824027;
        text-decoration: underline;
    }
    
    .a:not([href]) {
        color: inherit;
        text-decoration: none;
    }
    .a:not([href]):hover {
        color: inherit;
        text-decoration: none;
    } */

/* CLASS NAVBAR-TOGGER */
    /* .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
    .navbar-toggler:hover, .navbar-toggler:focus {
        text-decoration: none;
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
      } */

/* BUTTON */
/* button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  [role=button] {
    cursor: pointer;
  }
  
  select {
    word-wrap: normal;
  }
  
  button,
  [type=button],
  [type=reset],
  [type=submit] {
    -webkit-appearance: button;
  }
  
  button:not(:disabled),
  [type=button]:not(:disabled),
  [type=reset]:not(:disabled),
  [type=submit]:not(:disabled) {
    cursor: pointer;
  }
  
  button::-moz-focus-inner,
  [type=button]::-moz-focus-inner,
  [type=reset]::-moz-focus-inner,
  [type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  } */

/* CLASS COLLAPSE
    .collapse:not(.show) {
        display: none;
      }

/* CLASS NAVBAR-COLLAPSE */
    /* .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    } */

/* UL */
    /* ul {
        margin-top: 0;
        margin-bottom: 1rem;
    } */

/* CLASS NAVBAR-NAV     */
    /* .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    } */

    /* .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
      } */

/* NAV-LINK */
    /* .nav-link {
        display: block;
        padding: 0.5rem 1rem;
    }
    .nav-link:hover, .nav-link:focus {
        text-decoration: none;
    }
    .nav-link.disabled {
        color: #6c757d;
        pointer-events: none;
        cursor: default;
    } */ 